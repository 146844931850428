import styled from 'styled-components';
import { colors, fontSize } from '../constants';
import { Menu } from 'semantic-ui-react';

export const MainContainer = styled.div`
  color: ${colors.black};
  width: 100%;
  margin: 0 auto;
  font-size: ${fontSize.normal};
`;

export const HeaderItem = styled.a`
  font-size: ${fontSize.normal};
  text-transform: uppercase;
  word-spacing: 2px;
`;

export const LayoutMenu = styled(Menu).attrs({
  inverted: true,
  pointing: true,
  secondary: true,
  size: 'large',
})`
  margin: 0 auto;
  font-weight: bold;

  .item {
    font-weight: bold !important;
  }
`;

export const BackToTopWrapper = styled.div.attrs({
  id: 'topbtn',
})`
  position: fixed;
  top: 90%;
  left: 95%;
  transform: translate(-10%, -5%);
  background: linear-gradient(45deg, #22a6b3, #30336b);
  width: 50px;
  height: 50px;
  justify-content: center;
  align-items: center;
  color: ${colors.white};
  cursor: pointer;
  display: none;

  @media only screen and (max-width: 720px) {
    left: 90%;
  }

  @media only screen and (max-width: 385px) {
    left: 86%;
  }
`;

export const MediaWrapper = styled.div`
  @media only screen and (max-height: 863px) {
    .backtotop {
      display: block;
    }
  }
`;
