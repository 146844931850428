import styled from 'styled-components';
import { Step } from 'semantic-ui-react';
import { Steps } from 'antd';

import { SkillsWrapper, WrapperHeader } from '../../components/skills.styled';
import { colors, fontSize } from '../../constants';

const { Description } = Step;

export const HistorysWrapper = styled(SkillsWrapper).attrs({
  id: 'historys',
})`
  background: ${colors.hoverGrey};
`;

export const HistorysHeader = styled(WrapperHeader)`
  ::after {
    border: 3px solid ${colors.shadePurple};
  }
`;

export const StepDescription = styled(Description)`
  text-align: left;
  font-size: ${fontSize.normal} !important;
  margin: 10px 0;
`;

export const AntdSteps = styled(Steps).attrs(({ progressDot, direction }) => ({
  progressDot: progressDot,
  direction: direction,
}))`
  padding: 10px;

  .ant-steps-item-content {
    width: auto;

    .ant-steps-item-title {
      margin-bottom: 10px;
    }

    .ant-steps-item-subtitle {
      font-weight: bold;
      margin-left: 0;
    }

    .ant-steps-item-description {
      font-size: ${fontSize.normal};
      color: ${colors.black} !important;
    }
  }
`;
