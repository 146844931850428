import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useWindowScroll } from 'react-use';
import {
  Responsive,
  Visibility,
  Segment,
  Menu,
  Container,
  Message,
  Icon,
  Image,
  Sidebar,
} from 'semantic-ui-react';

import logoImage from '../images/logo.png';
import * as Styled from './layout.styled';

const Layout = ({
  children,
  setViewHeight,
  setMessageVisible,
  checkMessageVisible,
  ...restProps
}) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [activeMenu, setActiveMenu] = useState('home');
  const { y } = useWindowScroll();
  const fixedHeight = visible ? 70 : 0;
  const fixedHeaderHeight = window.innerWidth <= 768 ? 156 : 0;

  const { intro, skill, history, visible } = restProps;

  const getWidth = () => {
    const isSSR = typeof window === 'undefined';

    return isSSR ? Responsive.onlyTablet.minWidth : window.innerWidth;
  };

  const setKeyandScroll = (key, y) => {
    setActiveMenu(key);
    window.scroll({ top: y, left: 0, behavior: 'smooth' });
  };

  const handleMessageClose = () => {
    setMessageVisible(false);
    localStorage.setItem('hideMessage', '1');
  };

  const validatePosition = y => {
    if (y < intro + fixedHeight && y >= 0) {
      setActiveMenu('intro');
    } else if (y >= intro + fixedHeight && y < skill + fixedHeight) {
      setActiveMenu('skillsets');
    } else if (y >= skill + fixedHeight && y < history + fixedHeight) {
      setActiveMenu('historys');
    } else if (y >= history + fixedHeight) {
      setActiveMenu('contacts');
    }
  };

  const validateScroll = () => {
    if (y >= intro) {
      document.getElementById('topbtn').style.display = 'flex';
    } else {
      document.getElementById('topbtn').style.display = 'none';
    }
  };

  useEffect(() => {
    setViewHeight();
    validateScroll();
    validatePosition(y);

    if (visible) {
      checkMessageVisible();
    }
  });

  return (
    <>
      <Responsive getWidth={getWidth} minWidth={Responsive.onlyTablet.minWidth}>
        <Visibility
          once={false}
          onBottomPassed={() => {
            setMessageVisible(false);
          }}
        >
          <Message
            icon={<Icon name="check circle outline" />}
            success
            header="Hi there:"
            content="This website is develop with GatsbyJS, ReactJS, Styled Components, Semantic UI, Ant Design and Hover.css and still in development"
            onDismiss={handleMessageClose}
            hidden={!visible}
            style={{ margin: 0 }}
          />
          <Segment
            inverted
            vertical
            style={{
              background: 'linear-gradient(90deg,#3e0c1c 0,#2d0f5e)',
              width: '100%',
              position: 'fixed',
              zIndex: 999,
            }}
          >
            <Styled.LayoutMenu
              inverted={true}
              pointing={true}
              secondary={true}
              size="large"
            >
              <Container
                style={{
                  justifyContent: 'center',
                  letterSpacing: '2px',
                }}
              >
                <Menu.Item active={activeMenu === 'intro'}>
                  <Styled.HeaderItem
                    onClick={() => setKeyandScroll('intro', 0)}
                  >
                    Intro
                  </Styled.HeaderItem>
                </Menu.Item>
                <Menu.Item active={activeMenu === 'skillsets'}>
                  <Styled.HeaderItem
                    onClick={() =>
                      setKeyandScroll('skillsets', intro + fixedHeight)
                    }
                  >
                    Skills
                  </Styled.HeaderItem>
                </Menu.Item>
                <Menu.Item
                  style={{
                    padding: '0',
                    margin: '0 20px',
                  }}
                >
                  <Image
                    src={logoImage}
                    onClick={() => setKeyandScroll('intro', 0)}
                    style={{
                      borderRadius: '50%',
                      width: '50px',
                      height: '50px',
                      cursor: 'pointer',
                      top: '5px',
                    }}
                  />
                </Menu.Item>
                <Menu.Item active={activeMenu === 'historys'}>
                  <Styled.HeaderItem
                    onClick={() =>
                      setKeyandScroll('historys', skill + fixedHeight)
                    }
                  >
                    Historys
                  </Styled.HeaderItem>
                </Menu.Item>
                <Menu.Item active={activeMenu === 'contacts'}>
                  <Styled.HeaderItem
                    onClick={() =>
                      setKeyandScroll('contacts', history + fixedHeight)
                    }
                  >
                    Contacts
                  </Styled.HeaderItem>
                </Menu.Item>
              </Container>
            </Styled.LayoutMenu>
          </Segment>
        </Visibility>
        <Styled.MediaWrapper>
          <Styled.MainContainer>{children}</Styled.MainContainer>
          <Styled.BackToTopWrapper
            onClick={() =>
              window.scroll({ top: 0, left: 0, behavior: 'smooth' })
            }
          >
            <Icon name="arrow up" size="big" style={{ margin: '0' }} />
          </Styled.BackToTopWrapper>
        </Styled.MediaWrapper>
      </Responsive>
      {/* Mobile container  */}
      <Responsive
        as={Sidebar.Pushable}
        getWidth={getWidth}
        maxWidth={Responsive.onlyMobile.maxWidth}
      >
        <Sidebar
          as={Menu}
          animation="push"
          inverted
          onHide={() => setSidebarOpen(!sidebarOpen)}
          vertical
          visible={sidebarOpen}
        >
          <Container
            style={{
              justifyContent: 'center',
              letterSpacing: '2px',
            }}
          >
            <Menu.Item active={activeMenu === 'intro'}>
              <Styled.HeaderItem onClick={() => setKeyandScroll('intro', 0)}>
                Intro
              </Styled.HeaderItem>
            </Menu.Item>
            <Menu.Item active={activeMenu === 'skillsets'}>
              <Styled.HeaderItem
                onClick={() =>
                  setKeyandScroll(
                    'skillsets',
                    intro + fixedHeight + fixedHeaderHeight
                  )
                }
              >
                Skills
              </Styled.HeaderItem>
            </Menu.Item>
            <Menu.Item active={activeMenu === 'historys'}>
              <Styled.HeaderItem
                onClick={() =>
                  setKeyandScroll(
                    'historys',
                    skill + fixedHeight + fixedHeaderHeight
                  )
                }
              >
                Historys
              </Styled.HeaderItem>
            </Menu.Item>
            <Menu.Item active={activeMenu === 'contacts'}>
              <Styled.HeaderItem
                onClick={() =>
                  setKeyandScroll(
                    'contacts',
                    history + fixedHeight + fixedHeaderHeight
                  )
                }
              >
                Contacts
              </Styled.HeaderItem>
            </Menu.Item>
          </Container>
        </Sidebar>

        <Sidebar.Pusher dimmed={sidebarOpen}>
          <Segment
            inverted
            style={{
              background: 'linear-gradient(90deg,#3e0c1c 0,#2d0f5e)',
            }}
            vertical
          >
            <Container>
              <Menu inverted pointing secondary size="large">
                <Menu.Item onClick={() => setSidebarOpen(true)}>
                  <Icon name="sidebar" />
                </Menu.Item>
              </Menu>
            </Container>
          </Segment>
          <Styled.MediaWrapper>
            <Styled.MainContainer>{children}</Styled.MainContainer>
            <Styled.BackToTopWrapper
              onClick={() =>
                window.scroll({ top: 0, left: 0, behavior: 'smooth' })
              }
            >
              <Icon name="arrow up" size="big" style={{ margin: '0' }} />
            </Styled.BackToTopWrapper>
          </Styled.MediaWrapper>
        </Sidebar.Pusher>
      </Responsive>
    </>
  );
};

const mapStateToProps = state => ({
  intro: state.introH,
  skill: state.skillsH,
  history: state.historyH,
  visible: state.visible,
});

const mapDispatchToProps = dispatch => ({
  setViewHeight: () => dispatch({ type: 'SET_VIEW_HEIGHT' }),
  checkMessageVisible: () => dispatch({ type: 'CHECK_MESSAGE_VISIBLE' }),
  setMessageVisible: visible =>
    dispatch({ type: 'SET_MESSAGE_VISIBLE', payload: visible }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
