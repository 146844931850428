import styled from 'styled-components';

import img from '../images/purpledev.jpg';

export const MainContentWrapper = styled.div.attrs({
  id: 'mainContent',
})`
  width: 100%;
  height: auto;
  background: no-repeat center url(${img});
  background-attachment: fixed;
  background-size: cover;
  padding: 50px 0;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
`;
