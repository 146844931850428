import { colors as constentColors } from '../constants';

let colors = ['#FFAE57', '#FF7853', '#EA5151', '#CC3F57', '#9A2555'];
let bgColor = constentColors.darkPurple;

let itemStyle = {
  star5: {
    color: colors[0],
  },
  star4: {
    color: colors[1],
  },
  star3: {
    color: colors[2],
  },
};

let data = [
  {
    name: 'Front\nEnd',
    itemStyle: {
      color: colors[1],
    },
    children: [
      {
        name: 'JS\nLibrary',
        children: [
          {
            name: 'Proficiency',
            children: [
              {
                name: 'React',
              },
              {
                name: 'Redux',
              },
              {
                name: 'Reselect',
              },
              {
                name: 'RamdaJS',
              },
              {
                name: 'Styled Components',
              },
              {
                name: 'Redux Saga',
              },
            ],
          },
          {
            name: 'Excellent',
            children: [
              {
                name: 'GatsbyJS',
              },
              {
                name: 'Recompose',
              },
            ],
          },
          {
            name: 'Good',
            children: [
              {
                name: 'NextJS',
              },
            ],
          },
        ],
      },
      {
        name: 'Lan\nguages',
        children: [
          {
            name: 'Proficiency',
            children: [
              {
                name: 'JavaScript',
              },
              {
                name: 'HTML5',
              },
              {
                name: 'CSS3',
              },
            ],
          },
          {
            name: 'Excellent',
            children: [
              {
                name: 'PHP',
              },
            ],
          },
          {
            name: 'Good',
            children: [
              {
                name: 'Python',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: 'Back\nEnd',
    itemStyle: {
      color: colors[2],
    },
    children: [
      {
        name: 'Deve\nlopment',
        children: [
          {
            name: 'Proficiency',
            children: [
              {
                name: 'NodeJS',
              },
              {
                name: 'Express',
              },
            ],
          },
          {
            name: 'Excellent',
            children: [
              {
                name: 'Amazone Web Services',
              },
              {
                name: 'Serverless',
              },
            ],
          },
          {
            name: 'Good',
            children: [
              {
                name: 'NuxtJS',
              },
            ],
          },
        ],
      },
      {
        name: 'Data\nbase',
        children: [
          {
            name: 'Proficiency',
            children: [
              {
                name: 'MySQL',
              },
              {
                name: 'MongoDB',
              },
            ],
          },
          {
            name: 'Excellent',
            children: [
              {
                name: 'ComsoDB',
              },
              {
                name: 'DynamoDB',
              },
            ],
          },
          {
            name: 'Good',
            children: [
              {
                name: 'GraphQL',
              },
            ],
          },
        ],
      },
    ],
  },
];

// eslint-disable-next-line no-unused-vars
const nameSwitch = (name, bookScoreId) => {
  switch (name) {
    case 'Proficiency':
      bookScoreId = 0;
      return itemStyle.star5;
    case 'Excellent':
      bookScoreId = 1;
      return itemStyle.star4;
    case 'Good':
      bookScoreId = 2;
      return itemStyle.star3;
    default:
      return;
  }
};

for (let j = 0; j < data.length; ++j) {
  let level1 = data[j].children;
  for (let i = 0; i < level1.length; ++i) {
    let block = level1[i].children;
    let bookScore = [];
    let bookScoreId;
    for (let star = 0; star < block.length; ++star) {
      let style = (name => nameSwitch(name, bookScoreId))(block[star].name);

      block[star].label = {
        color: style.color,
        downplay: {
          opacity: 0.5,
        },
      };

      if (block[star].children) {
        style = {
          opacity: 1,
          color: style.color,
        };
        block[star].children.forEach(book => {
          book.value = 1;
          book.itemStyle = style;

          book.label = {
            color: style.color,
          };

          let value = 1;
          if (bookScoreId === 0 || bookScoreId === 3) {
            value = 5;
          }

          if (bookScore[bookScoreId]) {
            bookScore[bookScoreId].value += value;
          } else {
            bookScore[bookScoreId] = {
              color: colors[bookScoreId],
              value: value,
            };
          }
        });
      }
    }

    level1[i].itemStyle = {
      color: data[j].itemStyle.color,
    };
  }
}

export const options = {
  backgroundColor: bgColor,
  color: colors,
  series: [
    {
      type: 'sunburst',
      center: ['50%', '48%'],
      data: data,
      sort: function(a, b) {
        if (a.depth === 1) {
          return b.getValue() - a.getValue();
        } else {
          return a.dataIndex - b.dataIndex;
        }
      },
      label: {
        rotate: 'radial',
        color: bgColor,
        fontWeight: 900,
        fontSize: 13,
      },
      itemStyle: {
        borderColor: bgColor,
        borderWidth: 2,
      },
      levels: [
        {},
        {
          r0: 0,
          r: 40,
          label: {
            rotate: 0,
          },
        },
        {
          r0: 40,
          r: 105,
        },
        {
          r0: 115,
          r: 140,
          itemStyle: {
            shadowBlur: 2,
            shadowColor: colors[2],
            color: 'transparent',
          },
          label: {
            rotate: 'tangential',
            fontSize: 10,
            color: colors[0],
          },
        },
        {
          r0: 140,
          r: 145,
          itemStyle: {
            shadowBlur: 80,
            shadowColor: colors[0],
          },
          label: {
            position: 'outside',
            textShadowBlur: 5,
            textShadowColor: '#333',
          },
          downplay: {
            label: {
              opacity: 0.5,
            },
          },
        },
      ],
    },
  ],
};
