import React from 'react';

import * as Styled from './IconCard.styled';
import { ContactIcon } from '../ContactIcon';
import { MediaIcon } from '../MediaIcon';

export const IconCard = ({ name, value, icon, href, size = 'big' }) => {
  return (
    <Styled.IconCard>
      {href ? (
        <a
          href={href}
          target={name !== 'error' && '_blank'}
          rel={name !== 'error' && 'noopener noreferrer'}
        >
          <MediaIcon icon={icon} className={name} size="small" />
        </a>
      ) : (
        <>
          <ContactIcon icon={icon} className={name} size={size} />
          {value && <p>{value}</p>}
        </>
      )}
    </Styled.IconCard>
  );
};
