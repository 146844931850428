import React from 'react';

import * as Styled from './mainContents.styled';
import Skills from '../components/skills';
import Historys from '../views/Historys/historys';
export default () => (
  <Styled.MainContentWrapper>
    <Skills />
    <Historys />
  </Styled.MainContentWrapper>
);
