import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import * as Styled from './contacts.styled';
import { IconCard } from '../../components/IconCard';

export default () => {
  const data = useStaticQuery(graphql`
    query ContactsQuery {
      site {
        siteMetadata {
          contacts {
            name
            value
            icon
          }
          socialMedia {
            name
            href
            icon
          }
        }
      }
    }
  `);

  const getIconCards = cards =>
    cards.map((item, index) => (
      <Styled.GridColumn key={`${item.name} ${index}`}>
        <IconCard {...item} />
      </Styled.GridColumn>
    ));

  return (
    <Styled.ContactsWrapper>
      <Styled.ContactHeader>Contacts</Styled.ContactHeader>
      <Styled.ContactGridWrapper>
        <Styled.GridColumn>
          <Styled.DoublingIconGridWrapper
            columns={data.site.siteMetadata.contacts.length}
          >
            {getIconCards(data.site.siteMetadata.contacts)}
          </Styled.DoublingIconGridWrapper>
        </Styled.GridColumn>
        <Styled.GridColumn>
          <Styled.DoublingIconGridWrapper
            columns={data.site.siteMetadata.socialMedia.length}
          >
            {getIconCards(data.site.siteMetadata.socialMedia)}
          </Styled.DoublingIconGridWrapper>
        </Styled.GridColumn>
      </Styled.ContactGridWrapper>
    </Styled.ContactsWrapper>
  );
};
