import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';

export const StyledMediaIcon = styled(Icon).attrs(
  ({ icon, className, size }) => ({
    name: icon,
    className: `${className}Icon`,
    size: size,
  })
)`
  display: flex !important;
  justify-content: center;
  align-items: center;
  width: 60px !important;
  height: 60px !important;
  margin: 0 !important;
  z-index: 2;
  transition: 0.3s ease-in;
`;
