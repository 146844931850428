import React from 'react';
import ReactEcharts from 'echarts-for-react';
import echarts from 'echarts/lib/echarts';

import * as Styled from './skills.styled';
import { options } from './skills.config';

export default () => (
  <Styled.SkillsWrapper>
    <Styled.WrapperHeader>My Skill Sets</Styled.WrapperHeader>
    <ReactEcharts
      echarts={echarts}
      notMerge={true}
      lazyUpdate={true}
      option={options}
      style={{
        height: '500px',
        width: '100%',
      }}
    />
  </Styled.SkillsWrapper>
);
