import styled from 'styled-components';
import { Grid } from 'semantic-ui-react';

import { WrapperHeader } from '../../components/skills.styled';
import { colors } from '../../constants';

const { Column } = Grid;

export const ContactsWrapper = styled.div.attrs({
  id: 'contacts',
})`
  background: linear-gradient(90deg, #3e0c1c 0, #2d0f5e);
  max-width: 100%;
  height: 100%;
  padding: 30px 0;
`;

export const ContactHeader = styled(WrapperHeader)`
  color: ${colors.white};

  ::after {
    border: 3px solid ${colors.blue};
  }
`;

export const ContactGridWrapper = styled(Grid).attrs({
  doubling: true,
  divided: true,
  columns: 2,
})`
  max-width: 70%;
  margin: 0 auto !important;
  margin-top: -1rem;

  @media only screen and (max-width: 700px) {
    max-width: 90%;
  }
`;

export const DoublingIconGridWrapper = styled(Grid).attrs({
  doubling: true,
})``;

export const GridColumn = styled(Column)`
  a {
    text-decoration: none;
    font-size: 3rem !important;
    width: 60px !important;
    height: 60px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${colors.white};
    position: relative;
    margin: 0 12px;

    ::before {
      content: '';
      position: absolute;
      width: 60px;
      height: 60px;
      background: linear-gradient(45deg, #22a6b3, #30336b);
      border-radius: 50%;
      transition: 0.3s ease-in;
      z-index: 1;
    }

    :hover::before {
      transform: scale(0);
    }

    :hover {
      i {
        background: linear-gradient(45deg, #22a6b3, #30336b);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        transform: scale(2.2);
      }
    }
  }
`;
