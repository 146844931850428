import styled from 'styled-components';

import { colors } from '../constants';

export const LoadingWrapper = styled.div`
  background: ${colors.grey};
  width: 100vw;
  height: 100vh;

  @keyframes loading {
    50% {
      height: 45px;
    }
  }
`;

export const LoadingSpinner = styled.div.attrs({
  className: 'loading',
})`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(75deg);
  width: 15px;
  height: 15px;

  ::before,
  ::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 15px;
    height: 15px;
    border-radius: 15px;
    animation: loading 1.5s infinite linear;
  }

  ::before {
    box-shadow: 15px 15px #e77f67, -15px -15px #778beb;
  }

  ::after {
    content: '';
    box-shadow: 15px 15px #f8a5c2, -15px -15px #f5cd79;
    transform: translate(-50%, -50%) rotate(90deg);
  }
`;
