import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';

import { colors } from '../../constants';

export const StyledContactIcon = styled(Icon).attrs(
  ({ icon, className, size }) => ({
    name: icon,
    className: `${className}Icon`,
    size: size,
  })
)`
  color: ${colors.white} !important;
  background: ${colors.blue};
  width: 60px !important;
  height: 60px !important;
  text-align: center;
  line-height: 60px !important;
  border-radius: 50%;
  transition: 0.3s linear;
`;
