import React from 'react';
import { Step, Grid, Divider, Image } from 'semantic-ui-react';
import { Steps } from 'antd';
import { graphql, useStaticQuery } from 'gatsby';

import * as Styled from './historys.styled';
import accordoImg from '../../images/accordoLogo_color.png';
import autImage from '../../images/aut.jpg';
import ezyVetLogo from '../../images/ezyVetLogo.png';
import gzImage from '../../images/gzflix.png';
import threepmLogo from '../../images/3pmLogo.png';
import { createMarkup } from '../../utils';

export default () => {
  const data = useStaticQuery(graphql`
    query HistoryQuery {
      site {
        siteMetadata {
          educations {
            degree
            school
            startDate
            endDate
            description
          }
          employment {
            company
            title
            startDate
            endDate
            description
          }
        }
      }
    }
  `);

  const companyImages = {
    Accordo: accordoImg,
    ezyVet: ezyVetLogo,
    '3PM': threepmLogo,
    'GZ Technoglogy': gzImage,
  };

  const getEducationDetails = () =>
    data.site.siteMetadata.educations.map((item, index) => (
      <Steps.Step
        title={
          <div>
            <Image src={autImage} style={{ width: '100px', height: '60px' }} />
            {item.degree} - {item.school}
          </div>
        }
        subTitle={`${item.startDate} - ${
          item.endDate ? item.endDate : 'Present'
        }`}
        key={`${item.name} ${index}`}
      />
    ));

  const getEmploymentHistory = () =>
    data.site.siteMetadata.employment.map((item, index) => (
      <Step
        active={!item.endDate}
        completed={item.endDate}
        key={`${item.company} ${index}`}
      >
        <Image src={companyImages[item.company]} size="small" />
        <Step.Content>
          <Styled.StepDescription>
            {item.title} - ({item.startDate} -{' '}
            {item.endDate ? item.endDate : 'Present'})
            <Divider horizontal>-</Divider>
            {item.description && (
              <div
                dangerouslySetInnerHTML={createMarkup(item.description)}
              ></div>
            )}
          </Styled.StepDescription>
        </Step.Content>
      </Step>
    ));

  return (
    <Styled.HistorysWrapper>
      <Styled.HistorysHeader>My Historys</Styled.HistorysHeader>
      <Grid columns={2} doubling>
        <Grid.Column>
          <Step.Group vertical fluid>
            {getEmploymentHistory()}
          </Step.Group>
        </Grid.Column>
        <Grid.Column>
          <Styled.AntdSteps progressDot direction="vertical">
            {getEducationDetails()}
          </Styled.AntdSteps>
        </Grid.Column>
      </Grid>
    </Styled.HistorysWrapper>
  );
};
