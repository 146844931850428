import styled from 'styled-components';

import { colors, fontSize, mixins } from '../../constants';

export const IconCard = styled.div`
  ${mixins.flexCenter()}
  height: 200px;
  flex-direction: column;
  cursor: pointer;

  p {
    color: ${colors.white};
    font-size: ${fontSize.normal};
    opacity: 0;
    transition: 0.3s linear;
    max-height: 0;
    letter-spacing: 2px;
  }

  :hover {
    p {
      max-height: 40px;
      opacity: 1;
    }

    i {
      background: none;
      color: ${colors.white} !important;
      transform: scale(1.6);
    }
  }
`;
