import styled from 'styled-components';

import { colors, fontSize } from '../constants';
import img from '../images/code.jpg';

export const SkillsWrapper = styled.div.attrs({
  id: 'skillsets',
})`
  max-width: 70%;
  padding: 20px;
  margin: 0 auto;
  background: ${colors.darkPurple};
  border-radius: 50px;
  margin-bottom: 50px;

  :hover {
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    position: relative;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: transform;
    transition-property: transform;
  }

  ::before {
    pointer-events: none;
    position: absolute;
    z-index: -1;
    content: '';
    top: 100%;
    left: 5%;
    height: 10px;
    width: 90%;
    opacity: 0;
    background: -webkit-radial-gradient(
      center,
      ellipse,
      #6c5ce7 0%,
      rgba(0, 0, 0, 0) 80%
    );
    background: radial-gradient(
      ellipse at center,
      #6c5ce7 0%,
      rgba(0, 0, 0, 0) 80%
    );
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: transform, opacity;
    transition-property: transform, opacity;
  }

  &:hover,
  :focus,
  :active {
    color: ${colors.white};
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }

  &:hover::before,
  :focus::before,
  :active::before {
    opacity: 1;
    -webkit-transform: translateY(5px);
    transform: translateY(5px);
  }

  @media only screen and (max-width: 700px) {
    max-width: 90%;
  }
`;

export const WrapperHeader = styled.h1`
  font-size: ${fontSize.xxLarge};
  color: ${colors.blue};
  width: 100%;
  text-align: center;
  font-style: italic;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  margin-bottom: 2rem;
  font-weight: 900;

  ::after {
    content: '';
    display: block;
    width: 100px;
    border: 3px solid ${colors.blue};
    position: relative;
    left: 50%;
    transform: translate(-50%, 50%);
  }
`;

export const ChartTitle = styled.div`
  color: ${colors.black};
  font-size: ${fontSize.large};
  border-bottom: 1px solid ${colors.grey};
  font-weight: 600;
  margin-bottom: 10px;
  margin-top: 0;
  padding: 5px 0;
  text-align: center;
`;

export const ImageWrapper = styled.div`
  background: no-repeat center url(${img});
  background-attachment: fixed;
  background-size: cover;

  min-height: 300px;
`;
