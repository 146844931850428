import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';

import img from '../images/code.jpg';
import avatar from '../images/avatar.jpg';
import { colors, fontSize } from '../constants';

export const MainIntroWarpper = styled.div.attrs({
  id: 'mainIntro',
})`
  width: 100%;
  height: 100vh;
  background: no-repeat center url(${img});
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @keyframes clicknext {
    from {
      top: 20%;
    }

    to {
      top: 25%;
    }
  }
`;

export const AvatarWrapper = styled.div`
  width: 200px;
  height: 200px;
  margin-top: -100px;
  border-radius: 50%;
  background: no-repeat center url(${avatar});
`;

export const ClickNextWrapper = styled.div.attrs({
  className: 'clickNext',
})`
  position: relative;
  top: 20%;
  color: ${colors.lightGrey};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  animation: clicknext 3s infinite;
`;

export const ClickIcon = styled(Icon).attrs({
  name: 'angle double down',
  size: 'huge',
})``;

export const ClickDesc = styled.div`
  font-size: ${fontSize.large};
  font-weight: bold;
`;
