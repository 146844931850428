import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Header } from 'semantic-ui-react';

import * as Styled from './mainIntro.styled';

const MainIntro = ({ introHeight }) => {
  let TxtRotate = function(el, toRotate, period) {
    this.toRotate = toRotate;
    this.el = el;
    this.loopNum = 0;
    this.period = parseInt(period, 10) || 2000;
    this.txt = '';
    this.tick();
    this.isDeleting = false;
  };

  TxtRotate.prototype.tick = function() {
    let i = this.loopNum % this.toRotate.length;
    let fullTxt = this.toRotate[i];

    if (this.isDeleting) {
      this.txt = fullTxt.substring(0, this.txt.length - 1);
    } else {
      this.txt = fullTxt.substring(0, this.txt.length + 1);
    }

    this.el.innerHTML = '<span class="wrap">' + this.txt + '</span>';

    let that = this;
    let delta = 300 - Math.random() * 100;

    if (this.isDeleting) {
      delta /= 2;
    }

    if (!this.isDeleting && this.txt === fullTxt) {
      delta = this.period;
      this.isDeleting = true;
    } else if (this.isDeleting && this.txt === '') {
      this.isDeleting = false;
      this.loopNum++;
      delta = 500;
    }

    setTimeout(function() {
      that.tick();
    }, delta);
  };

  useEffect(() => {
    let elements = document.getElementsByClassName('txt-rotate');
    for (let i = 0; i < elements.length; i++) {
      let toRotate = elements[i].getAttribute('data-rotate');
      let period = elements[i].getAttribute('data-period');
      if (toRotate) {
        new TxtRotate(elements[i], JSON.parse(toRotate), period);
      }
    }
  }, []);

  return (
    <Styled.MainIntroWarpper>
      <Styled.AvatarWrapper />
      <Header as="h1" icon inverted style={{ fontSize: '36px' }}>
        Frankie Zheng
      </Header>
      <Header as="h1" inverted style={{ margin: 0, textAlign: 'center' }}>
        An experienced&nbsp;
        <span
          className="txt-rotate"
          data-period="2000"
          data-rotate='["Full-Stack Developer", "Full-Stack Engineer", "Web Developer"]'
          style={{ color: '#eb4d4b' }}
        ></span>
      </Header>
      <Styled.ClickNextWrapper
        onClick={() =>
          window.scroll({ top: introHeight, left: 0, behavior: 'smooth' })
        }
      >
        <Styled.ClickIcon />
        <Styled.ClickDesc>NEXT</Styled.ClickDesc>
      </Styled.ClickNextWrapper>
    </Styled.MainIntroWarpper>
  );
};

const mapStateToProps = ({ introH }) => ({
  introHeight: introH,
});

export default connect(mapStateToProps, null)(MainIntro);
