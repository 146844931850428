import React, { useState, useLayoutEffect } from 'react';
import 'semantic-ui-css/semantic.min.css';
import 'antd/dist/antd.css';
import 'hover.css/css/hover-min.css';
import './index.css';

import Layout from '../components/layout';
import SEO from '../components/seo';
import MainIntro from '../components/mainIntro';
import MainContents from '../components/mainContents';
import Loader from '../components/loader';
import Contacts from '../views/Contacts/contacts';

const LOADING_TIME_OUT = 3000;

export default () => {
  const [loading, setLoading] = useState(true);

  useLayoutEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, LOADING_TIME_OUT);
  });

  return (
    <div>
      <SEO title="myBios" />
      {loading ? (
        <Loader />
      ) : (
        <Layout>
          <MainIntro />
          <MainContents />
          <Contacts />
        </Layout>
      )}
    </div>
  );
};
